export const hashTextToColor = (text) => {
    let hash = 0;
    for (let i = 0; i < text.length; i++) {
        hash = text.charCodeAt(i) + ((hash << 5) - hash);
    }
    let colour = '#';
    for (let i = 0; i < 3; i++) {
        let value = (hash >> (i * 8)) & 0xFF;
        colour += ('00' + value.toString(16)).substr(-2);
    }
    return colour;
}

export const formatNumber = (value) => {
    if(value == null) return 0;
    let nf = new Intl.NumberFormat();
    return nf.format(value.toFixed(2));
}

export const chartAmountFormatter = (value) => {
    return "$" + chartNumberFormatter(value);
}

export const chartNumberFormatter = (value) => {
    if(value >= -1000 && value < 1000) return value;

    const suffixes = ["", "k", "m", "b", "t"];
    const suffixNum = Math.floor(("" + value).length / 3);
    let shortValue = parseFloat(
        (suffixNum != 0
            ? value / Math.pow(1000, suffixNum)
            : value
        ).toPrecision(2)
    );
    if (shortValue % 1 != 0) {
        shortValue = shortValue.toFixed(1);
    }
    return shortValue + suffixes[suffixNum];
}

export const chartPercentageFormatter = (value) => {
    return value + "%"
}