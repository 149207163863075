<template>
	<div v-if="!isLoading && !isLoadFailed" class="h-100" id="simple-loading">
		<slot />
	</div>

	<div v-else id="root">
		<div
			:class="{
				card: showLoadingCard && isLoading,
				'p-2': showLoadingCard && isLoading,
			}"
			v-show="isLoading"
			id="loading-container"
		>
			<b-skeleton animation="fade" width="85%" />
			<b-skeleton animation="fade" width="55%" />
			<b-skeleton animation="fade" width="70%" />
			<b-skeleton animation="fade" width="60%" />
			<b-skeleton animation="fade" width="80%" />
			<b-skeleton animation="fade" width="50%" />
		</div>

		<div
			:class="{
				card: showLoadFailedCard && isLoadFailed,
				'p-2': showLoadFailedCard && isLoadFailed,
			}"
			v-show="isLoadFailed && !isLoading"
			id="error-container"
		>
			<img
				style="margin-bottom: -20px; margin-top: -20px; max-heigth: 250px;"
				width="40%"
				:src="require('@/assets/images/kerry/loading-fail.jpeg')"
			/>

			<div id="retry-container" @click="onReload">
				<b-button variant="flat-secondary" class="btn-icon">
					<feather-icon icon="RefreshCcwIcon" />
					<span class="ml-1">Loading Fail. Click here to retry</span>
				</b-button>
			</div>
		</div>
	</div>
</template>

<script>
	import { BSpinner, BButton, BSkeleton } from "bootstrap-vue";
	import {} from "@vue/composition-api";

	export default {
		components: {
			BSpinner,
			BButton,
			BSkeleton,
		},
		props: {
			isLoading: {
				type: Boolean,
				required: true,
			},
			isLoadFailed: {
				type: Boolean,
				required: true,
			},
			showLoadingCard: {
				type: Boolean,
				required: false,
			},
			showLoadFailedCard: {
				type: Boolean,
				required: false,
			},
		},
		setup(props, ctx) {
			function onReload() {
				ctx.emit("reload");
			}
			return {
				onReload,
			};
		},
	};
</script>

<style scoped>
	#root {
		width: 100%;
		display: flex;
		/* align-items: center; */
		justify-content: center;
	}
	#loading-container {
		width: 100%;
	}
	#error-container {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		/* padding: 20px; */
	}
	#retry-container {
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
	}
</style>
