<template>
	<e-charts
		ref="myChart"
		:style="customStyle ? customStyle : 'height: 100%;'"
		:option="wrapperOptions"
		:theme="theme"
		@click="onClick"
		autoresize
	/>
</template>

<script>
	import ECharts from "vue-echarts";
	import "echarts/index.common";
	// import 'echarts/lib/component/tooltip'
	// import 'echarts/lib/component/legend'
	// import 'echarts/lib/chart/line'
	// import 'echarts/lib/chart/bar'
	// import 'echarts/lib/chart/pie'
	import 'echarts/lib/component/legendScroll'
	import { computed, onMounted, ref } from "@vue/composition-api";
	import theme from "@core/components/charts/echart/theme.json";

	export default {
		components: {
			ECharts,
		},
		props: {
			customStyle: {
				type: String,
				required: false,
			},
			options: {
				type: Object,
				required: true,
			},
		},
		setup(props, ctx) {
			const myChart = ref(null);

			function onClick(params) {
				ctx.emit('click', params);
			}

			const wrapperOptions = computed(() => ({
				// visualMap: [
				//   {
				//     show: true,
				//     type: 'continuous',
				//     min: 0,
				//     max: 400,
				//   },
				// ],

				...props.options,
				aria: {
					show: true,
				},
				toolbox: {
					show: true,
					feature: {
						// dataZoom: {
						// 	yAxisIndex: "none",
						// },
						dataView: { readOnly: false },
						// magicType: { type: ["line", "bar"] },
						// restore: {},
						saveAsImage: {},
					},
				},
				grid: {
					left: "70px",
					top: "30px",
					bottom: "50px",
					right: "70px",
					show: false,

					...props.options.grid,
				},
				legend: {
					x: "center",
					y: "bottom",
					type: 'scroll',

					...props.options.legend,
				},
				tooltip: {
					trigger: "axis",
					...props.options.tooltip,
				},
			}));

			return {
				myChart,
				wrapperOptions,
				theme,
				onClick
			};
		},
	};
</script>

<style scoped>
	.echarts {
		/* this value smaller is better because this value not useful after echart init */
		width: 100% !important;
	}
</style>
